import { headerLinks } from 'components/contants/links';
import FlexBox from 'components/flex-box/flex-box';
import Link from 'next/link';
import { createNavigation } from 'utils/create-navigation';
import { LogoMobile, StyledLink } from './style';

const Header = ({ lang = 'en' }: { lang?: string }) => {
  return (
    <FlexBox
      justifyContent={'space-between'}
      alignItems={'center'}
      height={'100%'}
      padding={1}
    >
      <FlexBox alignItems={'flex-start'} gap={1}>
        <Link
          href={createNavigation(lang)}
          style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}
        >
          <LogoMobile
            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/doo-graphics-logo.png`}
            alt="Doographics Logo"
            height={0}
            width={0}
          />
        </Link>
      </FlexBox>
      <FlexBox
        gap={2}
        alignItems={'center'}
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        {headerLinks.map((link) => (
          <Link href={createNavigation(lang, link.url)} key={link.url}>
            <StyledLink>{decodeURI(link.title)}</StyledLink>
          </Link>
        ))}
      </FlexBox>
      {/* <FlexBox gap={2} alignItems={'center'}>
        <SearchBar />
        <SideDrawer />
      </FlexBox> */}
    </FlexBox>
  );
};

export default Header;
